import React from "react"
import { Helmet } from "react-helmet"
import useSiteMetadata from "../hooks/use-site-metadata";
function SEO() {

  const {title,metaTitle,description,lang,siteUrl,logo,locale} = useSiteMetadata().site;
  return (
    <Helmet
      title={title}
      htmlAttributes={{
        lang,
      }}
      link={
        !!siteUrl
          ? [
            {
              rel: "canonical",
              href: siteUrl,
            },
          ]
          : []
      }
      meta={[
        {
          name: `title`,
          content: metaTitle
        },
        {
          name: `description`,
          content: description,
        },
        {
          name: `twitter:card`,
          content: "summary",
        },
        {
          name: `twitter:description`,
          content: description,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:image`,
          content: logo,
        },
        {
          name: `og:locale`,
          content: locale,
        },
        {
          name: `og:type`,
          content: `website`,
        },
        {
          name: `og:title`,
          content: title,
        },
        {
          name: `og:description`,
          content: description,
        },
        {
          name: `og:url`,
          content: siteUrl,
        },
        {
          name: `og:site name`,
          content: title,
        }

      ]}
    >
    </Helmet>
  );
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
}
export default SEO;
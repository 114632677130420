import React, { useState } from "react"
import scrollTo from "gatsby-plugin-smoothscroll"
import Scrollspy from "react-scrollspy"

import useSiteMetadata from "../hooks/use-site-metadata"

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars } from '@fortawesome/free-solid-svg-icons'
import content from "../../content/hero.yaml";

export default function () {
  const [isVisible, setVisibility] = useState(false)
  const {menuItems} = useSiteMetadata().site;

  let showStyle = null;
  if (isVisible) {
    showStyle = {transform: 'scaleY(1)'}
  } else {
    showStyle = null
  }

  return (
    <nav id="nav-wrap">
      <button
        id="toggle-btn"
        href="/#"
        title="Menu"
        onClick={() => setVisibility(!isVisible)}
      >
        <FontAwesomeIcon icon={faBars}/>
      </button>

      <Scrollspy
        className="nav mobile"
        style={showStyle}
        items={menuItems.map(a => a.path)}
        currentClassName="current"
        offset={-120}>
        {
          menuItems.map((value, index) => {
            const {path, label} = value;
            return (
              <li
                key={index}
              >
                {
                  path === "requestEstimate" ?
                    <button
                      id={"header-request-estimate-button"}
                      onClick={() => {
                        const a = document.createElement("a")
                        a.href = 'https://www.jotform.com/220124654216143';
                        a.target = "_blank";
                        a.rel = "noopener";
                        a.click();
                      }
                      }>
                    </button>
                    :
                    <button onClick={() => {
                      scrollTo('#' + path)
                      setVisibility(0)
                    }}>
                      {label}
                    </button>
                }
              </li>
            )
          })
        }
      </Scrollspy>
    </nav>
  );
};
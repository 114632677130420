import React,{useState} from "react";
import { AnchorLink } from "gatsby-plugin-anchor-links";
import useSiteMetadata from "../hooks/use-site-metadata";
import styled from "styled-components/macro";

import NavMenu from "./navmenu"
import HeaderSocial from "./header-social";

const scrollHeightForHeaderTransition = 54;
const scrollHeightForRequestEstimateDisplay = 700;

const headerTagName = "header";
const smallerHeaderClassName = "smaller-header";
const largerHeaderClassName = "larger-header";
const requestEstimateElementId = "header-request-estimate-button";

const StyledBrandNavBarHeading = styled.div`
  float:left;
  cursor:pointer;
`;

const scrollFunction = () => {
  const headerElement = document.getElementsByTagName(headerTagName);
  if (!!headerElement) {
    if (headerElement.length > 0) {
      const classList = [].slice.call(headerElement[0].classList);
      if (document.body.scrollTop > scrollHeightForHeaderTransition || document.documentElement.scrollTop > scrollHeightForHeaderTransition) {
        if (classList.indexOf(smallerHeaderClassName) < 0) {
          headerElement[0].classList.add(smallerHeaderClassName);
          headerElement[0].classList.remove(largerHeaderClassName);
        }
      } else {
        if (!!classList) {
          if (classList.length > 0) {
            if (classList.indexOf(smallerHeaderClassName) > -1) {
              headerElement[0].classList.add(largerHeaderClassName);
              headerElement[0].classList.remove(smallerHeaderClassName);
            }
          }
        }
      }
      const headerRequestEstimateElement = document.getElementById(requestEstimateElementId);
      if (!!headerRequestEstimateElement) {
        const classList = [].slice.call(headerRequestEstimateElement.classList);
        if (document.body.scrollTop > scrollHeightForRequestEstimateDisplay || document.documentElement.scrollTop > scrollHeightForRequestEstimateDisplay) {
          if (classList.indexOf(requestEstimateElementId) < 0) {
            headerRequestEstimateElement.innerHTML = "Request Estimate";
            headerRequestEstimateElement.classList.add(requestEstimateElementId);
          }
        } else {
          if (classList.indexOf(requestEstimateElementId) > -1) {
            headerRequestEstimateElement.innerHTML = "";
            headerRequestEstimateElement.classList.remove(requestEstimateElementId);
          }
        }
      }
    }
  }
};

const Header = () => {
  const [scrollFunctionAdded, setScrollFunctionAdded] = useState(false);
  const {logo} = useSiteMetadata().site;
  if (!scrollFunctionAdded) {
    setScrollFunctionAdded(true);
    if (typeof window !== 'undefined') {
      window.onscroll = function () {
        scrollFunction();
      };
    }
  }

  const [isTimerStarted, setIsTimerStarted] = useState(false);
  const [isReadyToShowNavbar, setIsReadyToShowNavbar] = useState(false);

  if (!isTimerStarted) {
    setIsTimerStarted(true);
    setTimeout(() => {
      setIsReadyToShowNavbar(true);
      setTimeout(() => scrollFunction(),200);
    }, 700)
  }


  return isReadyToShowNavbar ?
    <header className="larger-header">
      <div className="logo">
        <AnchorLink to="/#top">
          <img
            alt="Connecticut Fence and Gate Logo in Navigation Header"
            src={logo}
          />
        </AnchorLink>
      </div>
      <AnchorLink to="/#top">
        <StyledBrandNavBarHeading>
          <p>Connecticut</p>
          <p>Fence & Gate</p>
        </StyledBrandNavBarHeading>
      </AnchorLink>
      <NavMenu/>
      <HeaderSocial/>
    </header> : null
};

export default Header;
import React, { useState } from 'react';
import styled from "styled-components/macro";
import Lightbox from "react-image-lightbox";
import {LazyLoadImage} from "react-lazy-load-image-component";
import nonCapitalizedWords from "../modules/nonCapitalizedWords";
import imageConfiguration from '../../content/images.yaml';
import useImageThumbnail from "../hooks/use-image-thumbnail";
import 'react-lazy-load-image-component/src/effects/blur.css';
import 'react-image-lightbox/style.css';

const StyledGalleryContainer = styled.div`
  text-align:center;
`;

const StyledImageContainer = styled.span`
  transition: border-width 0.2s linear;
  padding:3px;
  border-radius: 7px;
  cursor:pointer;
  display:inline-block;
  border:5px;
  &:hover {
    transition: border-width 0.2s linear;
    border:0px;
  }
`;
const ImageSection = ({sectionName}) => {

  const thumbnailQuery = useImageThumbnail();
  const [photoIndex, setPhotoIndex] = useState(0);
  const [isOpen, setIsOpen] = useState(false);

  const content = imageConfiguration[sectionName];
  const {description, images, title} = content;

  const generateGraphQLName = (imageSrc) => imageSrc.split("images\/").join("").split(".jpg").join("").split("-").join("_").split("\/").join("_");
  const fullImageQuery = (imageSrc) => generateGraphQLName(imageSrc);
  const smallImageQuery = (imageSrc) => generateGraphQLName(imageSrc).split("_2000").join("_100");

  const generateImageTitle = (imageSrc) => {
    const folderSplit = imageSrc.split("/");
    const fileNameSplit = folderSplit[folderSplit.length - 1].split(".");
    return fileNameSplit[0].split("_100").join("").split("_500").join("").split("_1000").join("").split("_2000").join("").split("-").map((item) => nonCapitalizedWords.indexOf(item) === -1 ? `${item[0].toUpperCase()}${item.substring(1)}` : item).join(" ");
  };

  const imageGalleryArray = !!images && images.length > 0 ? images.map((item, index) => {
    const {image} = item;
    const fullImagePath = thumbnailQuery[fullImageQuery(image)].childImageSharp.fixed.src;
    const smallThumbnailPath = thumbnailQuery[smallImageQuery(image)].childImageSharp.fixed.src;
    const currentTitle = generateImageTitle(image);
    return {
      src: fullImagePath,
      thumbnail: smallThumbnailPath,
      title: currentTitle,
      index
    };
  }) : [];

  const clickImage = (index, e) => {
    e.preventDefault();
    setIsOpen(true);
    setPhotoIndex(index);
  };

  const closeImageLightbox = () => setIsOpen(false);

  return (
    <section id={`${sectionName}`} className="gallery">
      <div className="row section-head">
        <h1>{title}</h1>
        <p>{description}</p>
      </div>
      <StyledGalleryContainer className="row">
        {
          imageGalleryArray.map((item, index) => {
            const {thumbnail, title} = item;
            return (
              <StyledImageContainer
                key={index}
                onClick={clickImage.bind(this, index)}
                title={title}
              >
                <LazyLoadImage
                  height={100}
                  width={100}
                  delayTime={10000}
                  effect="blur"
                  alt={title}
                  src={thumbnail}
                />
              </StyledImageContainer>
            );
          })
        }
      </StyledGalleryContainer>
      <div>
        {
          isOpen && (
            <Lightbox
              mainSrc={`${imageGalleryArray[photoIndex].src}`}
              nextSrc={`${imageGalleryArray[(photoIndex + 1) % imageGalleryArray.length].src}`}
              prevSrc={`${imageGalleryArray[(photoIndex + images.length - 1) % imageGalleryArray.length].image}`}
              onCloseRequest={closeImageLightbox}
              onMovePrevRequest={() => setPhotoIndex((photoIndex + imageGalleryArray.length - 1) % imageGalleryArray.length)}
              onMoveNextRequest={() => setPhotoIndex((photoIndex + 1) % images.length)}
              imageTitle={`${imageGalleryArray[photoIndex].title}`}
              clickOutsideToClose={true}
              enableZoom={false}
              imageLoadErrorMessage={"Image Loading..."}
            />
          )
        }
      </div>
    </section>
  );
};

export default ImageSection;
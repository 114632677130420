import React from "react"
import { AnchorLink } from "gatsby-plugin-anchor-links"
import useSiteMetadata from "../hooks/use-site-metadata"
import scrollTo from "gatsby-plugin-smoothscroll"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronUp, faMapMarkerAlt, faUsers, faEnvelope } from '@fortawesome/free-solid-svg-icons'

const Footer = () => {
  const {social, footer, address, contacts} = useSiteMetadata().site;
  return (
    <footer>
      <div className="row">
        <div className="six columns info">

          <div className="footer-logo">
            <AnchorLink to="/">
              <img src={footer.logo} alt="Connecticut Fence and Gate Logo in Footer"/>
            </AnchorLink>
          </div>

          <p>{footer.text_1}</p>
          <p>{footer.text_2}</p>
          <p>{footer.text_3}</p>

        </div>

        <div className="six columns right-cols">
          <div className="row">
            <div className="columns">
              <FontAwesomeIcon icon={faMapMarkerAlt}/>
              <h3 className="address">Come Visit</h3>
              <h3>By appointment only</h3>
              <p>
                {address.line1}<br/>
                {address.line2}<br/>
                {address.line3}
              </p>
            </div>

            <div className="columns">
              <FontAwesomeIcon icon={faUsers}/>
              <h3 className="social">Socialize</h3>
              <ul>
                {social.map((item, index) =>
                  <li
                    key={index}
                  >
                    <a
                      href={item.url}
                    >{item.service}</a>
                  </li>
                )}
              </ul>
            </div>
            <div className="columns last">
              <FontAwesomeIcon icon={faEnvelope}/>
              <h3 className="contact">Contact Us</h3>
              <ul>
                {contacts.map((contact, index) =>
                  <li
                    key={index}
                  >
                    <a
                      href={contact.url}
                    >{contact.text}</a>
                  </li>
                )}
              </ul>
            </div>
          </div>
        </div>
        <p className="copyright">&copy;{`${new Date().getFullYear()} Connecticut Fence & Gate`}</p>
        <div id="go-top" style={{paddingTop: "100px"}}>
          <button title="Back to Top" onClick={() => scrollTo('#top')}>
            <FontAwesomeIcon icon={faChevronUp}/>
          </button>
        </div>
      </div>
    </footer>
  );
};

export default Footer;